import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Menu from '../components/menu';
import Layout from '../components/layout';
import React, { useEffect, useState } from 'react';
import SectionDisplay from '../components/sectiondisplay';

function createSearchObjects(openBooks) {
  return openBooks.map(ob => {
    return ob.Sections.reduce(function(akku, sec) {
      return [...akku, `${sec.Id}_${sec.Parent.toLowerCase()}_${sec.Keywords.toLowerCase()}`];
    }, []);
  });
}

function filterSearchResults(searchTerm, searchObjects) {
  const results = [];
  if(searchTerm) {
    const terms = searchTerm.trim().replace(/\s+/g, ' ').toLowerCase().split(' ');
    for (var i = 0; i < searchObjects.length; i++) {
      const obSearchObjects = searchObjects[i];
      for (var j= 0; j < obSearchObjects.length; j++) {
        if(terms.every(term => obSearchObjects[j].includes(term))) {
           results.push(obSearchObjects[j]);
        }
      }
    }
  }
  return results;
}

export default ({ pageContext: { openBooks }}) => {

  const [searchObjects, setSearchObjects] = useState(createSearchObjects(openBooks));
  useEffect(() => {
    setSearchObjects(createSearchObjects(openBooks));
  }, [openBooks]);

  const [searchTerm, setSearchTerm] = useState('');
  useEffect(() => {
    if (searchTerm) {
      setSearchResults(filterSearchResults(searchTerm, searchObjects));
    }
  }, [searchTerm]);

  const [searchResults, setSearchResults] = useState([]);

  function handleSearchChange(event) {
    if (event.target.value.length > 3) {
      setSearchTerm(event.target.value);
    }
  }

  function onCrossClick(event) {
    setSearchResults([]);
    setSearchTerm('');
  }

  function createSupportTextTag(openbook) {
    if(!openbook.Supported) return '';
    return openbook.Supported.map(s => <p>{s.Text}</p>);
  }

  const openBookStore = openBooks.map((ob, index) => {
                          return <div key={index} className="gridFraction">
                                    <Link className="tooltip" to={ob.Url}><img className="obImage" src={`/images/${ob.Image}`} alt="OpenBook" />
                                      <span className="flyout">
                                        <strong>{ob.Title}</strong><br /><br />
                                        {ob.Description}<br/> 
                                        {createSupportTextTag(ob)}
                                        {ob.Supported?.map(s => <img src={s.Image} style={{ 'marginRight': '12px'}} />)}
                                      </span>
                                    </Link>
                                    <p>{ob.Title}, {ob.Edition !== "1" && `${ob.Edition}/`}{ob.Year}</p>
                                 </div>});

  return (
    <Layout>
      <div className="jumbotron">
        <Menu localPath="/" />
        <h1>Open Educational Resources zur Musik</h1>
        <h2>von Ulrich Kaiser</h2>
        <p className="jumbotron-text">
          Die OpenBooks auf dieser Seite werden unter der Lizenz CC BY-SA zur Verfügung gestellt.
          Aktuell sind <span className="obHighlighted">&nbsp;{new Intl.NumberFormat('de-DE').format(openBooks.length)} OpenBooks&nbsp;</span> verfügbar!
        </p>
        <hr />
        <input
          className="searchInput"
          type="search"
          id="tutorials-filter"
          placeholder="Suchbegriff eingeben"
          onChange={handleSearchChange}
        />
      </div>
      {!searchResults?.length && <div className="indexGridBox">
        {openBookStore}
      </div>}
      {!!searchResults?.length && <div className="searchResults">
        <button onClick={onCrossClick} className="closeX"><span>X</span></button>
        {searchResults.map((result, index) => {
          const resultParts = result.split('_');
          const obs = openBooks.find(ob => ob.Title.toLowerCase() === resultParts[1]);
          const sec = obs.Sections.find(s => s.Id === resultParts[0]);
          return <SectionDisplay key={index} section={sec} folderpath={obs.Folderpath} />
        })}
      </div>}
      <Helmet>
        <title>OER | Ulrich Kaiser</title>
        <meta name="description" content="OER (Open Educational Resources) von Prof. Dr. Ulrich Kaiser" />
        <meta name="keywords" content="OER, Open Educational Resources, OpenBooks" />
        <meta name="copyright" content="Ulrich Kaiser, 2011" />
      </Helmet>
    </Layout>
  )
}
